import { v2Colors, shadows } from '@web-for-marketing/react-ui';
import { DropdownSize } from '../UserMenuDropdown';

export const userMenuDropdownStyles = {
    dashboardLink: {
        color: v2Colors.core.silver,
        textDecoration: 'none',
        fontSize: '2rem',
    },
    userButton: (size: DropdownSize) => ({
        border: '1px solid #C1C9D6',
        borderRadius: '4rem',
        padding: '0.5rem 1rem',
        background: v2Colors.core.snow,
        color: v2Colors.core.geotab,
        cursor: 'pointer',
        display: 'flex',
        zIndex: 500,
        alignItems: 'center',
        '& > p': {
            color: 'inherit',
            fontSize: size === 'small' ? '1.4rem' : '1.6rem',
        },
        '&:hover': {
            backgroundColor: v2Colors.core.cloud,
        },
        '&:focus': {
            borderColor: v2Colors.core.innovation,
            color: v2Colors.core.innovation,
            outline: 'none',
            '& > svg > path': {
                stroke: 'currentColor',
            },
        },
    }),
    userIcon: {
        borderRadius: '50%',
        border: `1px solid ${v2Colors.core.geotab}`,
        marginRight: '1rem',
        '@media (max-width: 400px)': {
            display: 'none',
        },
    },
    imageIcon: (size: DropdownSize) => ({
        width: size === 'small' ? '1.6rem' : '2.5rem',
        height: size === 'small' ? '1.6rem' : '2.5rem',
    }),
    textIcon: {
        background: v2Colors.core.geotab,
        fontWeight: 700,
        color: v2Colors.core.snow,
        padding: '0.3rem 0.8rem',
    },
    navItemContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    menuContainer: (size: DropdownSize) =>
        ({
            position: 'relative',
            '&::after': {
                content: '""',
                height: '100%',
                width: '100%',
                position: 'absolute',
                background: size === 'small' ? 'transparent' : v2Colors.core.snow,
            },
        }) as const,
    menuOptions: (size: DropdownSize) =>
        ({
            pointerEvents: 'none',
            zIndex: -1,
            position: 'absolute',
            top: size === 'small' ? '110%' : 'auto',
            bottom: size === 'small' ? 'auto' : '0',
            opacity: 0,
            right: 0,
            width: 'auto',
            transform: size === 'small' ? 'translateY(-1rem)' : 'translateY(0)',
            transition: `transform 300ms ease, z-index 300ms ${
                size === 'small' ? ' ease' : 'step-start'
            }, opacity 300ms ease`,
            boxShadow: shadows.soft,
            '& > *': {
                cursor: 'pointer',
                color: v2Colors.core.blackTarr,
                fontSize: size === 'small' ? '1.4rem' : '1.6rem',
                backgroundColor: v2Colors.core.snow,
                border: '1px solid #C1C9D6',
                padding: '1rem 2rem',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                whiteSpace: 'nowrap',
                '&:hover': {
                    backgroundColor: v2Colors.core.cloud,
                },
                '&:focus': {
                    borderColor: v2Colors.core.innovation,
                    color: v2Colors.core.innovation,
                    outline: 'none',
                },
                '&:first-child': {
                    borderTopRightRadius: size === 'small' ? '1rem' : '',
                    borderTopLeftRadius: size === 'small' ? '1rem' : '',
                },
                '&:last-child': {
                    borderBottomRightRadius: '1rem',
                    borderBottomLeftRadius: '1rem',
                },
            },
        }) as const,
    menuOpen: (size: DropdownSize) =>
        ({
            zIndex: 900,
            transform: size === 'small' ? 'none' : 'translateY(100%)',
            opacity: '1',
            transition: `transform 300ms ease, z-index 300ms ${
                size === 'small' ? ' ease' : 'step-end'
            }, opacity 300ms ease`,
            pointerEvents: 'auto',
        }) as const,
    dropdownIcon: {
        verticalAlign: 'text-top',
        transition: 'transform 300ms ease',
        marginLeft: '1rem',
    },
    rotate: {
        transform: 'rotate(180deg)',
    },
    tooltip: {
        border: `1px solid ${v2Colors.core.smoke}`,
        color: v2Colors.core.blackTarr,
        backgroundColor: v2Colors.core.snow,
        boxShadow: shadows.soft,
    },
    background: {
        position: 'fixed',
        content: '""',
        width: '100%',
        height: '100%',
        display: 'block',
        top: 0,
        left: 0,
        zIndex: 3,
    },
} as const;
